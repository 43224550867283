<div class="">
    <h1 class="mt-2">Admin Settings</h1>
    <ol class="breadcrumb ml-2 mr-2 mt-2">
      <li class="breadcrumb-item">
        <a href="#">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a href="#">Library</a>
      </li>
      <li class="breadcrumb-item active">Settings</li>
    </ol>
    <div class="mr-3 ml-3">
        <tabset>
          <tab heading="User" id="tab1">
            <app-admin-user></app-admin-user>
          </tab>
          <tab heading="Terminal">
            <app-admin-terminal-tab></app-admin-terminal-tab>
          </tab>
          <tab heading="Financial Data">
            <app-admin-financial-tab></app-admin-financial-tab>
          </tab>
          <tab heading="Export">Basic content 2</tab>
        </tabset>
    </div>
</div>