<div class="card mt-3">
    <div class="card-header">
      Create New Export
    </div>
    <div class="card-body">
      <form #addExportForm="ngForm" (ngSubmit)="addExport()">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="inputTerminal">Export Terminal</label>
            <select class="form-control" id="inputTerminal" name="terminalID" 
            [(ngModel)]="model.terminalID" #terminalID="ngModel" (change)="onTerminalChange(model.terminalID)"
              required>
              <option *ngFor="let terminal of terminals" value="{{terminal.id}}">{{terminal.name}}</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="inputCargoNumber">Unique Cargo Numger</label>
            <input type="text" class="form-control" id="inputCargoNumber" placeholder="AGB-098" name="cargoNumber" [(ngModel)]="model.cargoNumber"
              #cargoNumber="ngModel" required>
          </div>
          <div class="form-group col-md-4">
            <label for="inputProductStream">Select Product Stream</label>
            <select class="form-control" id="inputProductStream" name="productID" 
            [(ngModel)]="model.productID" (change)="onSelectProduct(model.productID)" #productID="ngModel"
              required>
              <option *ngFor="let product of products" value="{{product.id}}">{{product.name}}</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="inputLifter">Select Lifter</label>
            <select class="form-control" id="inputLifter" name="lifterID"
             [(ngModel)]="model.lifterID" #lifterID="ngModel" (change)="onSelectLifter(model.lifterID)" required>
              <option *ngFor="let lifter of lifters" value="{{lifter.id}}">{{lifter.name}}</option>
            </select>
          </div>
          <div class="form-group col-md-3">
            <label for="inputNominatedVolume">Nominated Volume</label>
            <input type="text" class="form-control" id="inputNominatedVolume" placeholder="Nominated Volume (MB)" [(ngModel)]="model.nominatedVolume"
              name="nominatedVolume" #nominatedVolume="ngModel" required>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <div class="row">
              <div class="col-md-10">
                <label for="inputVesselName">Vessel Name</label>
                <input type="text" class="form-control" id="inputVesselName" placeholder="vessel Name" [(ngModel)]="model.vesselName" name="vesselName"
                  #vesselName="ngModel" [typeahead]="vessels" typeaheadOptionField="name" (typeaheadOnSelect)="onSelectVessel($event)">
              </div>
              <div class="col-md-2 mt-2">
                <button type="button" class="btn btn-outline-primary" (click)="openModal(template)">
                  <i class="fa fa-plus-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="inputProductStream">Cargo Type</label>
            <select class="form-control" id="inputProductStream" #cargoTypeID="ngModel" name="cargoTypeID" [(ngModel)]="model.cargoTypeID"
              required>
              <option *ngFor="let cargotype of cargotypes" value="{{cargotype.id}}">{{cargotype.name}}</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="inputDateRange">Date Range</label>
            <input class="form-control" id="inputDateRange" name="dateRange" #dateRange="ngModel" [(ngModel)]="model.dateRange" required
              placeholder="Daterangepicker" bsDaterangepicker>
          </div>
          <div class="form-group col-md-4">
            <label for="inputETA">ETA</label>
            <input class="form-control" id="inputETA" [(ngModel)]="model.ETA" placeholder="Datepicker" #ETA="ngModel" name="ETA" bsDatepicker>
          </div>
        </div>
      
        <div class="form-row ">
          <div class="form-group col-md-6">
            <div class="row">
              <div class="col-md-10">
                <label for="inputInspector">Inspector</label>
                <input type="text" class="form-control" id="inputInspector" [(ngModel)]="model.inspector" placeholder="Enter Inspector" #inspector="ngModel"
                  name="inspector" [typeahead]="InspectorList" typeaheadOptionField="name" (typeaheadOnSelect)="onSelectInspector($event)">
              </div>
              <div class="col-md-2">
                <button type="button" class="btn btn-outline-primary" (click)="openModal(newInspectorModal)">
                  <i class="fa fa-plus-circle"></i></button>
              </div>
            </div>
            
          </div>
          <div class="form-group col-md-6">
            <div class="row">
              <div class="col-md-10">
                <label for="inputDWTofVessel">Vessel Agent</label>
                <input type="text" class="form-control" id="inputVesselAgent" [(ngModel)]="model.VesselAgentID" placeholder="Select Vessel Agent"
                  #vesselAgent="ngModel" name="vesselAgent" [typeahead]="vesselAgentList" typeaheadOptionField="name">
              </div>
              <div class="col-md-2">
                <button type="button" class="btn btn-outline-primary" (click)="openModal(newAgentModal)">
                  <i class="fa fa-plus-circle"></i>
                </button>
              </div>
            </div>
            
          </div>
        </div>
      
        <div class="form-row">
          <div class="form-group col-md-12">
            <div class="row">
              <div class="col-md-10">
                <label for="inputConsignee">Consignee</label>
                <select class="form-control" id="inputConsignee" #consigneeID="ngModel" name="consigneeID" [(ngModel)]="model.consigneeID">
                  <option *ngFor="let consignee of consignees" value="{{consignee.id}}">{{consignee.name}}</option>
                </select>
              </div>
              <div class="col-md-2">
                <button type="button" class="btn btn-outline-primary" (click)="openModal(newConsigneeModal)">
                  <i class="fa fa-plus-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-10">
            <label for="inputDWTofVessel">Consignor</label>
            <input type="text" class="form-control" id="inputConsignor" [(ngModel)]="model.ConsignorID" placeholder="Select Consignor"
              #consignor="ngModel" name="consignor" [typeahead]="consignorsList" typeaheadOptionField="name" (typeaheadOnSelect)="onSelectConsignor($event)">
          </div>
          <div class="col-md-2">
            <button type="button" class="btn btn-outline-primary" (click)="openModal(newConsignorModal)">
              <i class="fa fa-plus-circle"></i>
            </button>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputDWTofVessel">DWT of Vessel</label>
            <input type="text" class="form-control" id="inputDWTofVessel" [(ngModel)]="model.DWTofVessel" placeholder="Enter DWT of Vessel"
              #DWTofVessel="ngModel" name="DWTofVessel">
          </div>
          <div class="form-group col-md-6">
            <label for="inputFLAFofVessel">FLAG of Vessel</label>
            <input type="text" class="form-control" id="inputFLAFofVessel" [(ngModel)]="model.flagOfVessel" placeholder="Vessel ETA"
              #flagOfVessel="ngModel" name="flagOfVessel">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-10">
            <label for="inputDestination">Destination</label>
            <input type="text" class="form-control" id="inputDestination" [(ngModel)]="model.destination" placeholder="Enter Destination"
              #destination="ngModel" name="destination" [typeahead]="destinationList" typeaheadOptionField="name" (typeaheadOnSelect)="onSelectDestination($event)">
          </div>
          <div class="col-md-2">
            <button type="button" class="btn btn-outline-primary" (click)="openModal(newDestinationModal)">
              <i class="fa fa-plus-circle"></i>
            </button>
          </div>
        </div>
      
        <div class="form-group">
          <label for="inputComment">Export Comment</label>
          <textarea class="form-control" id="inputComment" rows="4" #comment="ngModel" name="comment" [(ngModel)]="model.comment"></textarea>
        </div>
      
      
        <div class="modal-footer">
          <div class="form-row">
            <button type="button" class="btn btn-outline-danger btn-lg mr-2" (click)="cancel()">Close</button>
            <!-- <button type="button" class="btn btn-outline-default btn-lg mr-2" id="butClearDailyProduction">Clear Field</button> -->
            <!-- <button [disabled]="!addExportForm.valid" type="submit" class="btn btn-danger mr-1">Save changes</button> -->
            <button type="submit" class="btn btn-outline-primary btn-lg mr-2">Save changes</button>
            <!-- <button type="submit" class="btn btn-danger mr-1" (click)="confirmSave()">Save changes</button> -->
          </div>
        </div>
      </form>
    </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add New Vessel</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #addVesselForm="ngForm">
    <div class="modal-body">
      <div class="form-row">
        <input type="text" class="form-control" placeholder="Vessel Name" [(ngModel)]="vesselToAddModel.name" name="vesselToAddModel.name" required>
      </div>
      <div class="form-row mt-2">
        <input type="number" class="form-control" placeholder="IMO Number" [(ngModel)]="vesselToAddModel.IMONumber" name="vesselToAddModel.IMONumber" required>
      </div>
      <div class="form-row mt-2">
        <input type="number" class="form-control" placeholder="DWT of Vessel" [(ngModel)]="vesselToAddModel.dwt" name="vesselToAddModel.dwt" required>
      </div>
      <div class="form-row mt-2">
        <input type="text" class="form-control" placeholder="Vessel FLAG" [(ngModel)]="vesselToAddModel.flag" name="vesselToAddModel.flag" required>
      </div>
      <button type="button" [disabled]="!addVesselForm.valid" class="btn btn-primary mt-2" (click)="addNewVessel()">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #newAgentModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Agent</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #addAgentForm="ngForm">
    <div class="modal-body">
      <div class="form-row">
        <input type="text" class="form-control" autofocus placeholder="Vessel Name" [(ngModel)]="vesselAgentToAddModel.name" name="vesselAgentToAddModel.vesselName" required>
      </div>
      <button type="button" [disabled]="!addAgentForm.valid" class="btn btn-primary mt-2" (click)="addNewVesselAgent()">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #newInspectorModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Inspector</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #addInspectorForm="ngForm">
    <div class="modal-body">
      <div class="form-row">
        <input type="text" class="form-control" placeholder="Inspector Name" [(ngModel)]="inspeectorToAddModel.name" name="inspeectorToAddModel.name" required>
      </div>
      <button type="button" class="btn btn-primary mt-2" [disabled]="!addInspectorForm.valid" (click)="addNewInspector()">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #newConsigneeModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Consignee</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #addConsigneeForm="ngForm">
    <div class="modal-body">
      <div class="form-row">
        <input type="text" class="form-control" placeholder="Consignee Name" [(ngModel)]="consigneeToAddModel.name" name="consigneeToAddModel.name" required>
      </div>
      <div class="form-row mt-2 mb-2">
        <select class="form-control" id="inputTerminal" name="consigneeToAddModel.terminalId" [(ngModel)]="consigneeToAddModel.terminalId"
          required>
          <option>Select A Terminal</option>
          <option *ngFor="let terminal of terminals" value="{{terminal.id}}">{{terminal.name}}</option>
        </select>
      </div>
      <button type="button" class="btn btn-primary mt-2" [disabled]="!addConsigneeForm.valid" (click)="addNewConsignee()">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #newConsignorModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Consignor</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #addConsignorForm="ngForm">
    <div class="modal-body">
      <div class="form-row">
        <input type="text" class="form-control" placeholder="Consignor Name" [(ngModel)]="consignorToAddModel.name" name="consignorToAddModel" required>
      </div>
      <div class="form-row mt-2 mb-2">
        <select class="form-control" id="inputLifter" name="consignorToAddModel.lifterId" [(ngModel)]="consignorToAddModel.lifterId"
          required>
          <option *ngFor="let lifter of lifters" value="{{lifter.id}}">{{lifter.name}}</option>
        </select>
      </div>
      <button type="button" class="btn btn-primary mt-2" [disabled]="!addConsignorForm.valid" (click)="addNewConsignor()">Save</button>
    </div>
  </form>
</ng-template>

<ng-template #newDestinationModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Destination</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #addDestinationForm="ngForm">
    <div class="modal-body">
      <div class="form-row">
        <input type="text" class="form-control" placeholder="Destination" [(ngModel)]="destinationToAddModel.name" name="destinationToAddModel.name" required>
      </div>
      <button type="button" class="btn btn-primary mt-2" [disabled]="!addDestinationForm.valid" (click)="addNewDestination()">Save</button>
    </div>
  </form>
</ng-template>