import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ExportService } from '../../_services/export.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { Demurrage } from '../../_models/demurrage';
import { DemurrageStatus } from '../../_models/demurragestatus';
import { Terminal } from 'src/app/_models/terminal';
import { Product } from 'src/app/_models/product';
import { Lifter } from 'src/app/_models/lifter';
import { CargoType } from 'src/app/_models/cargotype';
import { Vessel } from 'src/app/_models/Vessel';

@Component({
  selector: "app-open-demurrage-list",
  templateUrl: "./open-demurrage-list.component.html",
  styleUrls: ["./open-demurrage-list.component.css"]
})
export class OpenDemurrageListComponent implements OnInit {
  openDemurrages: any[];
  filteredOpenDemurrages: any[];
  today = Date.now();
  modalRef: BsModalRef;
  demurrageStatus: any[];
  selectedStatusId: any;
  selectedDemurrage: any = {};
  statusId = 1;
  model: any;

  terminals: Terminal[];
  products: Product[];
  lifters: Lifter[];
  cargotypes: CargoType[];
  vesselNames: any[];
  dateRange: any;
  enableLoader = true;

  demurrageToUpdate: number;

  // Filter 
  filterModel: {
    statusId: number;
    productName: string;
    lifterName: string;
    terminalName: string;
    vesselName: string
  } = {
      vesselName: '',
      terminalName: '',
      lifterName: '',
      productName: '',
      statusId: 0

    };

  constructor(
    private modalService: BsModalService,
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loadOpenDemurrageClaims();
    this.loadOpenDemurrageStatus();
    this.loadVessels();
    this.loadTerminals();
    this.loadLifters();
    this.loadProducts();
  }

  loadOpenDemurrageClaims() {
    this.exportService.getDemurrages().subscribe(
      (demurrages: Demurrage[]) => {
        this.openDemurrages = demurrages;
        this.filteredOpenDemurrages = this.openDemurrages;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadOpenDemurrageStatus() {
    this.exportService.getDemurrageStatus().subscribe(
      (demurrageStatus: DemurrageStatus[]) => {
        this.demurrageStatus = demurrageStatus;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  openModal(template: TemplateRef<any>, e: number) {
    this.demurrageToUpdate = e;
    this.modalRef = this.modalService.show(template);
  }
  updateDemurrageStatus() {
    this.exportService
      .updateDemurrageStatus(this.demurrageToUpdate, this.statusId, this.model)
      .subscribe(
        () => {
          this.alertify.success('Demurrage Status Updated');
          this.modalRef.hide();
          this.ngOnInit();
        },
        error => {
          this.alertify.error(error);
        }
      );
  }
  updateActivityStatus() {

  }

  loadVessels() {
    this.exportService.getVessels().subscribe(
      (vessels: Vessel[]) => {
        this.vesselNames = vessels;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  onSelect() {
  }
  loadCargoTypes() {
    this.exportService.getCargoTypes().subscribe(
      (cargotypes: CargoType[]) => {
        this.cargotypes = cargotypes;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadTerminals() {
    this.exportService.getTerminals().subscribe(
      (terminals: Terminal[]) => {
        this.terminals = terminals;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadProducts() {
    this.exportService.getProducts().subscribe(
      (products: Product[]) => {
        this.products = products;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadLifters() {
    this.exportService.getLifters().subscribe(
      (lifters: Lifter[]) => {
        this.lifters = lifters;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  runQuery() {
    //customSelected - vesselName
    //terminalName
    //lifterName
    //productName
    //dateRange
    //filterModel.statusId
    // this.filteredOpenDemurrages = this.openDemurrages
    //   .filter(
    //     el => (this.filterModel.vesselName.length > 0 && el.export.vessel.name.includes(this.customSelected))
    //     && (this.filterModel.terminalName.length > 0 && el.export.terminals.name === this.terminalName)
    //     && (this.filterModel.lifterName.length>0 && el.export.lifters.name === this.lifterName)
    //     && (this.filterModel.productName.length>0 && el.export.products.name === this.productName)
    //     //&& ()
    //     && (this.filterModel.statusId > 0 && el.demurrageStatus.id === this.statusId)
    //   );

    this.filteredOpenDemurrages = this.openDemurrages;

    if (this.filterModel.vesselName.length > 0)
      this.filteredOpenDemurrages = this.filteredOpenDemurrages.filter(el => el.export.vessel.name.includes(this.filterModel.vesselName));
    if (this.filterModel.terminalName.length > 0)
      this.filteredOpenDemurrages = this.filteredOpenDemurrages.filter(el => el.export.terminals.name === this.filterModel.terminalName);
    if (this.filterModel.lifterName.length > 0)
      this.filteredOpenDemurrages = this.filteredOpenDemurrages.filter(el => el.export.lifters.name === this.filterModel.lifterName);
    if (this.filterModel.productName.length > 0)
      this.filteredOpenDemurrages = this.filteredOpenDemurrages.filter(el => el.export.products.name === this.filterModel.productName);
    if (this.filterModel.statusId > 0)
      this.filteredOpenDemurrages = this.filteredOpenDemurrages.filter(el => el.demurrageStatus.id.toString() === this.filterModel.statusId);

  }
}
