import { Component, OnInit, Input } from '@angular/core';
import { Export } from '../_models/export';

@Component({
  selector: 'app-export-card',
  templateUrl: './export-card.component.html',
  styleUrls: ['./export-card.component.css']
})
export class ExportCardComponent implements OnInit {
  @Input() export: Export;

  constructor() { }

  ngOnInit() {
  }

}
