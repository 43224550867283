<div class="container">
  <h1 class="mt-2">Dash Board</h1>
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a href="#">Home</a>
    </li>
    <li class="breadcrumb-item">
      <a href="#">Dash Board</a>
    </li>
    <li class="breadcrumb-item active">Export Dash Board</li>
  </ol>
  <h5> Total Exports : {{pagination.totalItems}}</h5>
  <div>
    <!-- <pre class="card card-block card-header mb-3">Search For: {{customSelected | json}}</pre> -->
    <div class="row breadcrumb">
      <div class="col-sm-4">
        <label>Vessel Name</label>
        <input [(ngModel)]="customSelected" [typeahead]="vesselNames" typeaheadOptionField="name" class="form-control"
          (typeaheadOnSelect)="onSelect()">
      </div>
      <div class="col-sm-3">
        <label for="inputTerminal">Terminal</label>
        <select class="form-control" id="inputTerminal" name="terminalName" [(ngModel)]="terminalName" required>
          <option *ngFor="let terminal of terminals" value="{{terminal.name}}">{{terminal.name}}</option>
        </select>
      </div>
      <div class="col-sm-3">
        <label for="inputLifter">Lifter</label>
        <select class="form-control" id="inputLifter" name="lifterName" [(ngModel)]="lifterName" required>
          <option *ngFor="let lifter of lifters" value="{{lifter.name}}">{{lifter.label}}</option>
        </select>
      </div>
      <div class="col-sm-2">
        <label for="inputProductStream">Product</label>
        <select class="form-control" id="inputProductStream" name="productName" [(ngModel)]="productName" required>
          <option *ngFor="let product of products" value="{{product.name}}">{{product.name}}</option>
        </select>
      </div>
      <div class="col-sm-3">
        <label for="productNames">Date Start</label>
        <input type="text" placeholder="Date Start" class="form-control" [(ngModel)]="dateStart"
          (bsValueChange)="onDateStartChange($event)" name="dateStart" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
          bsDatepicker>
      </div>
      <div class="col-sm-3">
        <label for="productNames">Date End</label>
        <input type="text" placeholder="Date End" class="form-control" [(ngModel)]="dateEnd"
          (bsValueChange)="onDateEndChange($event)" name="dateEnd" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
          bsDatepicker>
      </div>
      <div class="col-sm-2">
        <label for="inputProductStream">Status</label>
        <select class="form-control" id="inputProductStream" name="statusId" [(ngModel)]="statusId" required>
          <option *ngFor="let status of statusList" value="{{status.id}}">{{status.label}}</option>
        </select>
      </div>

      <div class="col-sm-2">
        <label for="inputProductStream">Users</label>
        <select class="form-control" id="inputProductStream" name="userId" [(ngModel)]="userId" required>
          <option *ngFor="let user of usersList" value="{{user.id}}">{{user.username}}</option>
        </select>
      </div>

      <div class="col-sm-2">
        <label for="inputProductStream">Cargo Number</label>
        <input [(ngModel)]="selectedCargoNumber" [typeahead]="exports" typeaheadOptionField="cargoNumber"
          class="form-control" (typeaheadOnSelect)="onSelectCargoNumber($event)">
      </div>
    </div>
    <div class="mt-4 d-flex justify-content-end">
      <button class="btn btn-outline-primary mr-2" (click)="runQuery()"><i class="fa fa-search"></i> Search</button>
      <button class="btn btn-outline-info mr-2" (click)="clearFilter()"><i class="fa fa-search"></i> Clear</button>
      <button class="btn btn-outline-danger" (click)="openFilterNameModal(addFilterName)"><i class="fa fa-save"></i>
        Save Filter</button>
    </div>
    <div class="row">
      <div class="mt-4 d-flex justify-content-start">
        <button (click)="loadSelectedFilter(filter)" *ngFor="let filter of filterList" type="button"
          class="btn btn-info btn-sm mr-1 ml-1">{{filter.name}}</button>
      </div>
    </div>
  </div>
  <!-- <div class="alert alert-dismissible alert-warning">
    <button type="button" class="close" data-dismiss="alert">&times;</button>
    <h4 class="alert-heading">Clearance Delay Warning!</h4>
    <p class="mb-0">NNPC and DPR Clearance for MT OTHEMAN TENNACIY NOT AVAILABLE,
      <a href="#" class="alert-link">@ODEYOMI Dap</a>.</p>
  </div> -->
  <div *ngIf="enableLoader" class="container">
    <div class="row">
      <div id="loader">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="lading"></div>
      </div>
    </div>
  </div>
  <div>
    <div class="row mt-4" *ngIf="!enableLoader">
      <div *ngFor="let export of exports" class="col-md-3 col-sm-6">
        <app-dash-export-card [export]="export"></app-dash-export-card>
      </div>
    </div>

    <div class="d-flex justify-content-center" *ngIf="!enableLoader">
      <pagination [boundaryLinks]="true" [maxSize]="20" [rotate]="true" [totalItems]="pagination.totalItems"
        [itemsPerPage]="pagination.itemsPerPage" [(ngModel)]="+pagination.currentPage"
        (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
        lastText="&raquo;">
      </pagination>
    </div>
  </div>

  <!-- <app-dash-tab></app-dash-tab> -->
  <!-- <app-dash-table></app-dash-table> -->
  <!-- <app-dash-issue-board></app-dash-issue-board> -->
</div>

<ng-template #addFilterName>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Filter Name</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #filterNameForm="ngForm">
    <div class="modal-body">
      <input type="text" class="form-control" placeholder="Filter Name" [(ngModel)]="filterName" name="filterName"
        required>
      <fieldset>
        <div class="form-group mt-2">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitch1" checked="">
            <label class="custom-control-label" for="customSwitch1">Mark As Default</label>
          </div>
        </div>
      </fieldset>
    </div>
  </form>
  <div class="modal_footer">
    <button type="button" class="btn btn-primary btn-block mt-2" [disabled]="!filterNameForm.valid"
      (click)="saveFilter()">Save</button>

  </div>
</ng-template>