<div>
  <h4 class="text-muted">Issues Log</h4>
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">Type</th>
        <th scope="col">Column heading</th>
        <th scope="col">Column heading</th>
        <th scope="col">Column heading</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">Default</th>
        <td>Column content</td>
        <td>Column content</td>
        <td>Column content</td>
      </tr>
     
    </tbody>
  </table>
</div>
