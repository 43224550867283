import {Routes} from '@angular/router';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { ExportComponent } from './export/export.component';
import { LiftingProgramComponent } from './lifting-program/lifting-program.component';
import { DocumentComponent } from './document/document.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './_guards/auth.guard';
import { ExportDetailComponent } from './export-detail/export-detail.component';
import { ExportDetailResolver } from './_resolvers/export-detail.resolver';
import { ExportDetailUpdateComponent } from './export-detail-update/export-detail-update.component';
import { PreventUnsavedChangesGuard } from './_guards/prevent-unsaved-changes-guard';
import { ExportActionsListUpdateViewComponent } from './export-actions-list-update-view/export-actions-list-update-view.component';
import { NodeCommentResolver } from './_resolvers/node-comment.resolver';
import { ExportTableComponent } from './export-table/export-table.component';
import { ForecastHomeComponent } from './forecast/forecast-home/forecast-home.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { DemurrageHomeComponent } from './demurrage/demurrage-home/demurrage-home.component';
import { DemurrageListDetailComponent } from './demurrage/demurrage-list-detail/demurrage-list-detail.component';
import { FinancialHomeComponent } from './financial/financial-home/financial-home.component';
import { NessPayreqComponent } from './financial/ness-payreq/ness-payreq.component';
import { ExchangeRateComponent } from './financial/exchange-rate/exchange-rate.component';
import { CompletedNessComponent } from './financial/completed-ness/completed-ness.component';
import { DocumentListResolver } from './_resolvers/document-list.resolver';
import { ReportHomeComponent } from './report/report-home/report-home.component';

export const appRoute: Routes = [
    { path: 'home', component: HomeComponent },
    {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        children: [
            { path: 'dash-board', component: DashBoardComponent },
            { path: 'export', component: ExportComponent },
            { path: 'export/list', component: ExportTableComponent },
            { path: 'export-detail/:id', component: ExportDetailComponent,
                resolve: {export: ExportDetailResolver} },
            {path: 'export/update/:id', component: ExportDetailUpdateComponent,
                 resolve: { export: ExportDetailResolver }},
            {
                path: 'export/actions/update/:id/:exportId', component: ExportActionsListUpdateViewComponent,
                resolve: { export: ExportDetailResolver, nodecomment: NodeCommentResolver }
            },
            { path: 'lifting-program', component: LiftingProgramComponent },
            { path: 'document', component: DocumentComponent, resolve: {
                documents: DocumentListResolver
            } },
            {path: 'forecast', component: ForecastHomeComponent},
            {path: 'admin-home', component: AdminHomeComponent},
            {path: 'demurrage', component: DemurrageHomeComponent},
            {path: 'financial', component: FinancialHomeComponent},
            {path: 'ness-payreq', component: NessPayreqComponent},
            {path: 'completed-ness', component: CompletedNessComponent},
            {path: 'exchange-rate', component: ExchangeRateComponent},
            {path: 'demurrage-detail/:exportId', component: DemurrageListDetailComponent},
            {path: 'report', component: ReportHomeComponent}
        ]
    },
    { path: '**', redirectTo: 'home', pathMatch: 'full'}
];
