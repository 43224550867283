<div class="breadcrumb">
  <form #filterForm="ngForm">
      <div class="form-group row">
          <div class="form-group ml-1">
              <input type="text" class="form-control" id="exampleInputEmail1" style="width: 200px"
              placeholder="Enter Terminal Name" [(ngModel)]="terminalName" name="terminalName"
              [typeahead]="terminalArray" typeaheadOptionField="name" (typeaheadOnSelect)="onSelectTerminal($event)" >
          </div>

          <div class="form-group ml-1">
              <input type="text" class="form-control" id="exampleInputEmail1" style="width: 200px"
              placeholder="Enter Document Type" [(ngModel)]="documentCategoryname" name="documentCategoryname"
              [typeahead]="documentCategoryNameArray" typeaheadOptionField="name" (typeaheadOnSelect)="onSelectDocumentCategory($event)" >
          </div>

          <div class="form-group ml-1">
              <input type="text" class="form-control" id="exampleInputEmail1" style="width: 200px"
              placeholder="Cargo Number" [(ngModel)]="cargoNumber" name="cargoNumber"
              [typeahead]="exportArray" typeaheadOptionField="cargoNumber" (typeaheadOnSelect)="onSelectCargoNumber($event)" >
          </div>

          <div class="form-group ml-1">
              <input type="text" class="form-control" id="exampleInputEmail1" style="width: 200px"
              placeholder="Lifter Name" [(ngModel)]="lifterName" name="lifterName"
              [typeahead]="lifterArray" typeaheadOptionField="name" (typeaheadOnSelect)="onSelectLifterName($event)" >
          </div>

          <div class="form-group ml-1">
            <input type="text"
            placeholder="Daterangepicker"
            class="form-control"
            [(ngModel)]="dateStart" (bsValueChange)="onDateStartChange($event)"
            name="dateStart" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
            bsDatepicker>
          </div>
          <div class="form-group ml-1">
            <input type="text"
            placeholder="Daterangepicker"
            class="form-control"
            [(ngModel)]="dateEnd" (bsValueChange)="onDateEndChange($event)"
            name="dateEnd" [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
            bsDatepicker>
          </div>
      </div>
      <div class="form-group row">
          <button type="button" [disabled]="!filterForm.dirty || !filterForm.valid" (click)="loadDocuments()" class="btn btn-outline-primary mr-1"><i class="fa fa-search"></i> Search</button>
          <button type="button" (click)="resetFilter()" class="btn btn-outline-danger ml-1"><i class="fa fa-search"></i> Reset</button>
      </div>
    </form>  
</div>
<div *ngIf="enableLoader" class="container">
    <div class="row">
      <div id="loader">
          <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="lading"></div>
      </div>
    </div>
</div>

<div *ngIf="!enableLoader">
    <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Terminal</th>
            <th scope="col">Cargo # | Vessel | BOL Date</th>
            <th scope="col">Dcoument Type</th>
            <th scope="col">User</th>
            <th scope="col">
                <div class="btn-group" dropdown>
                    <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-info btn-sn dropdown-toggle" aria-controls="dropdown-basic">
                      Action <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li role="menuitem"><a class="dropdown-item" (click)="downloadAll(documentArray)">Download All</a></li>
                      <!-- <li role="menuitem"><a class="dropdown-item">Add Comments</a></li>
                      <li role="menuitem"><a class="dropdown-item">Generate Approval Letter</a></li> -->
                    </ul>
                  </div>
            </th>
          </tr>
        </thead>
        <tbody *ngFor="let document of documentArray; index as i">
          <tr>
            <th scope="row">{{i + 1}}</th>
            <td>{{document.terminals.name}} | {{document.exports.lifters.name}}</td>
            <td>{{document.exports.cargoNumber}} | {{document.exports.vessel.name}} | {{document.exports.bolDate | date}}</td>
            <td>{{document.documentCategory.name}}</td>
            <td>{{document.users.username}}<span class="badge badge-info">Added : {{document.dateCreated | timeAgo}}</span></td>
            <td>
                <div class="btn-group" dropdown>
                    <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-danger btn-sn dropdown-toggle" aria-controls="dropdown-basic">
                      Action <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li role="menuitem"><a class="dropdown-item" (click)="downloadDocument(document.id,document.fileName)">Download</a></li>
                      <!-- <li role="menuitem"><a class="dropdown-item">Add Comments</a></li>
                      <li role="menuitem"><a class="dropdown-item">Generate Approval Letter</a></li> -->
                    </ul>
                  </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center">
          <pagination [boundaryLinks]="true" 
            [totalItems]="pagination.totalItems" 
            [itemsPerPage]="pagination.itemsPerPage"
            [(ngModel)]="pagination.currentPage"
            (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
          </pagination>
     </div>
</div>