import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormGroup } from '@angular/forms';
import { Analyst } from '../_models/Analyst';
import { ExportService } from '../_services/export.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  modalRef: BsModalRef;
  addExportForm: UntypedFormGroup;
  model: any = {};
  modelForProfile: Analyst;
  public analyst = {
    firstName: null,
    middleName: null,
    lastName: null,
    phoneNumber: null,
    mobile: null,
    address: null,
    city: null,
    state: null,
    email: null,
    extension: null,
    userId: null,
    managerId: null
  };

  public analystForm = {
    firstName: null,
    middleName: null,
    lastName: null,
    phoneNumber: null,
    mobile: null,
    address: null,
    city: null,
    state: null,
    email: null,
    extension: null,
    userId: null,
  };
  userId = 1;

  constructor(
    private exportService: ExportService,
    private modalService: BsModalService,
    public authService: AuthService,
    private alertify: AlertifyService,
    private router: Router
  ) {}

  ngOnInit() {
  }

  login() {
    this.authService.login(this.model).subscribe(
      next => {
        this.userId = this.authService.decodedToken.nameid;        
        this.loadAnalyst(this.userId);
        this.alertify.success('Login was successful');
      },
      error => {
        console.log(error);
        if (error.status === 401){
          this.alertify.error("Invalid Username/Password");
        }else{
          this.alertify.error(error.message);
        }
      },
      () => {
        this.router.navigate(['/dash-board']);
      }
    );
  }

  loggedIn() {
    // this.userId = this.authService.decodedToken.nameid;
    // if (this.userId != null) {
    //   this.loadAnalyst(this.userId);
    // }

    return this.authService.loggedIn();
  }

  logout() {
    localStorage.removeItem('token'); // remove token from application
    this.alertify.message('You have logged Out');
    this.router.navigate(['/home']);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
  }
  confirmSave() {
    this.alertify.confirm('Are you sure you want to update your proifle', () => {
      this.exportService.updateUserProfile(this.analystForm).subscribe(
        () => {
          this.alertify.success('Your UserProfile is Successfully Updated');
        },
        error => {
          this.alertify.error('Server Error Unable to Update Profile');
        }
      );

      this.router.navigate(['/dash-board']);
    });
  }
  // Method called on clicking the submit button of the addExporForm
  updateProfile() {
    this.confirmSave();
  }

  loadAnalyst(id: number) {
    this.exportService.getExportAnalyst(id).subscribe(
      (analyst: Analyst) => {
        this.analyst = analyst;
        this.analystForm  = analyst;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
}
