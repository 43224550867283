<div class="d-flex justify-content-center">
  <nav class="navbar navbar-default">
    <!-- Global navigation -->
    
      <div class="navbar-collapse js-navbar-collapse centered row font-gotham" id="navbar-brand-centered">
        <div class="navbar-brand navbar-brand-centered">
          <div class="hallmark-container">
            <a title="Chevron" class="hallmark" [routerLink]="['/home']">
              <img width="42" height="46" alt="Chevron" src="../../assets/images/hallmark.png" style="display: block;margin-left: auto;margin-right: auto;"></a>
          </div>
          <div class="sitename-container">
            <a class="sitename light-blue font-gotham" [routerLink]="['/home']">Lifting Operations Management System</a>
          </div>
        </div>
      </div>

  </nav>
</div>


<nav class="navbar navbar-expand-md navbar-dark bg-dark">
      <!-- <a class="navbar-brand" [routerLink]="['/dash-board']"><img src="../../assets/Chevron-logo.png" width="50"></a> -->
      <!-- <a *ngIf="loggedIn()" class="nav-item" [routerLink]="['/dash-board']">Home</a> -->
      
      <ul class="navbar-nav mr-auto">
        <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/dash-board']" >Home | </a>
        </li>
        <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/export']" >Export Queue | </a>
        </li>
        <!-- <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/forecast']" > Lifting Forecast | </a>
        </li> -->
        <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/document']" > Document | </a>
        </li>
        <!-- <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/document']" > Notifications | </a>
        </li> -->
        <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/demurrage']" > Demurrage Claims | </a>
        </li>
        <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/financial']" > Financials | </a>
        </li>
        <!-- <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/report']" > Report | </a>
        </li> -->
        <!-- <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
              <a class="nav-link" [routerLink]="['/visualisation']" > Visualisation | </a>
          </li> -->
        <li *ngIf="loggedIn()" class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/admin-home']" > Admin | </a>
        </li>
      </ul>
      
      <div *ngIf="loggedIn()" class="dropdown" dropdown>
        <a class="dropdown-toggle text-light" dropdownToggle>Welcome {{authService.decodedToken?.unique_name | titlecase}}</a>
        <div class="dropdown-menu mt-3" *dropdownMenu>
          <a class="dropdown-item" (click)="lgModal.show()">
            <i class="fa fa-user"></i> Edit Profile</a>
            <a class="dropdown-item" [routerLink]="['/admin-home']" >
              <i class="fa fa-cogs"></i> Setting</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logout()">
            <i class="fa fa-sign-out"></i> Logout</a>
        </div>
      </div>
      
      <form *ngIf="!loggedIn()" #loginForm="ngForm" class="form-inline my-2 my-lg-0" (ngSubmit)="login()">
        <input class="form-control mr-sm-2" name="username" #username="ngModel" type="text" placeholder="UserName" required [(ngModel)]="model.username">
        <input class="form-control mr-sm-2" name="password" #password="ngModel" type="password" placeholder="Password" required [(ngModel)]="model.password">
        <button [disabled]="!loginForm.valid" class="btn btn-success my-2 my-sm-0" type="submit">Login</button>
      </form>
</nav>

<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 id="dialog-sizes-name1" class="modal-title pull-left">Edit Profile</h3>
        <button type="button" class="close pull-right" (click)="lgModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #addExportForm="ngForm" (ngSubmit)="updateProfile()">
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputTerminal">First Name</label>
              <input type="text" class="form-control"  [(ngModel)]="analystForm.firstName" name="analystForm.firstName" required>
            </div>
            <div class="form-group col-md-4">
              <label for="inputCargoNumber">Middle Name</label>
              <input type="text" class="form-control"  placeholder="Middle Name" [(ngModel)]="analystForm.middleName" name="analystForm.middleName" #analystForm.middleName required>
            </div>
            <div class="form-group col-md-4">
              <label for="inputProductStream">Last Name</label>
              <input type="text" class="form-control"  placeholder="Last Name" [(ngModel)]="analystForm.lastName" name="analystForm.lastName" required>

            </div>
            <div class="form-group col-md-4">
              <label for="inputLifter">Email</label>
              <input type="text" class="form-control"  placeholder="Email" [(ngModel)]="analystForm.email" name="analystForm.email" required>

            </div>
            <div class="form-group col-md-4">
              <label for="inputNominatedVolume">Extension</label>
              <input type="text" class="form-control" placeholder="Extension" [(ngModel)]="analystForm.extension" name="analystForm.extension"
                       required>
            </div>
            <div class="form-group col-md-4">
              <label for="inputVesselName">Phone</label>
              <input type="text" class="form-control"  placeholder="Phone Number" [(ngModel)]="analystForm.phoneNumber" name="analystForm.phoneNumber" required>

            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputProductStream">Mobile</label>
              <input type="text" class="form-control" placeholder="Mobile" [(ngModel)]="analystForm.mobile" name="analystForm.mobile"
               required>

            </div>
            <div class="form-group col-md-4">
              <label for="inputDateRange">City</label>
              <input type="text" class="form-control" placeholder="City" [(ngModel)]="analystForm.city" name="analystForm.city"
                 required>
            </div>
            <div class="form-group col-md-4">
              <label for="inputETA">State</label>
              <input type="text" class="form-control" placeholder="State" [(ngModel)]="analystForm.state" name="analystForm.state"
                required>
            </div>
          </div>

          <div class="form-group">
            <label for="inputComment"><Address></Address></label>
            <textarea class="form-control" rows="4" #comment="ngModel" name="analystForm.address" [(ngModel)]="analystForm.address"></textarea>
          </div>


          <div class="modal-footer">
            <div class="form-row">
              <button type="button" class="btn btn-outline-danger btn-lg mr-2" (click)="lgModal.hide()">Close</button>
              <!-- <button [disabled]="!addExportForm.valid" type="submit" class="btn btn-danger mr-1">Save changes</button> -->
              <button type="submit" class="btn btn-outline-primary btn-lg mr-2">Save changes</button>
              <!-- <button type="submit" class="btn btn-danger mr-1" (click)="confirmSave()">Save changes</button> -->
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
