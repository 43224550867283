import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Export } from '../_models/export';
import { ExportService } from '../_services/export.service';
import { AlertifyService } from '../_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Terminal } from '../_models/terminal';
import { Product } from '../_models/product';
import { Lifter } from '../_models/lifter';
import { CargoType } from '../_models/cargotype';
import { Consignee } from '../_models/consignee';
import { NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Status } from '../_models/status';
import { DocumentCategory } from '../_models/documentcategory';
import { AuthService } from '../_services/auth.service';
import { Destination } from '../_models/destination';
import { Inspector } from '../_models/inspector';
import { Vessel } from '../_models/Vessel';
import { VesselAgent } from '../_models/vesselagent';
import { Consignor } from '../_models/consignor';
import { DateService } from '../_services/date.service';





@Component({
  selector: 'app-export-detail-update',
  templateUrl: './export-detail-update.component.html',
  styleUrls: ['./export-detail-update.component.css']
})
export class ExportDetailUpdateComponent implements OnInit {
  @ViewChild('editExportForm') editExportForm:NgForm;
  @ViewChild('updateTab') staticTabs: TabsetComponent;

  terminals: Terminal[];
  products: Product[];
  lifters: Lifter[];
  cargotypes: CargoType[];
  consignees: Consignee[];
  statuses: Status[];
  modalRef: BsModalRef;

  model: any = {};
  modelForUpdateData: any = {};
  vessel: any = {};
  inspector: any = {};
  destination: any = {};
  vesselagent: any = {};
  documentTypes: any = {};

  userId: number;
  vessels: any[];
  vesselList: string[] = ['None'];
  destinationList: any = {};
  InspectorList: any = {};
  vesselAgentList: any = {};
  consignorsList: any = {};
  // vesselagents: any = {};
  selectedValue: string;
  selectedOption: any;

  selectedDestinationValue: string;
  selectedDestinationOption: any;

  selectedInspectorValue: string;
  selectedInspectorOption: any;

  hasDestination = false;
  hasVessel = false;
  hasInspector = false;

  isCreateMode = false;

  // Vessel Model
  vesselToAddModel: any = {};
  inspeectorToAddModel: any = {};
  vesselAgentToAddModel: any = {};
  consigneeToAddModel: any = {};
  consignorToAddModel: any = {};
  destinationToAddModel: any = {};

  productForSelectedTerminal: Product[];

  export: Export; // Make the Export model available in the view

  // Date Picker Customisation
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  hasDemurrage: any = { state: false };
  hasOutturn: any = { state: false };
  hasLossClaim: any = { state: false };
  // financial: any = {};

  dateRageStart: Date;


  constructor(
    private exportService: ExportService,
    private alertify: AlertifyService,
    private route: ActivatedRoute,
    private nav: Router,
    private authService: AuthService,
    private modalService: BsModalService,
    private dateService: DateService
  ) { }

  ngOnInit() {
    // this.loadExport();
    this.loadTerminals();
    this.loadProducts();
    this.loadLifters();
    this.loadCargoTypes();
    this.loadConsignees();
    this.loadStatus();
    this.loadVessels();
    this.loadDestinations();
    this.loadInspectors();
    this.loadVesselAgents();
    this.loadConsignor();

    this.route.data.subscribe(data => {
      this.export = data['export'];
      this.model = this.export;
      this.modelForUpdateData.destinationid = this.model.destinations.id;
      this.modelForUpdateData.inspectorId = this.model.inspector.id;
      this.modelForUpdateData.VesselAgentId = this.model.vesselAgents.id;
      this.modelForUpdateData.vesselId = this.model.vessel.id;
      this.modelForUpdateData.consignorId = this.model.consignor.id;
      this.modelForUpdateData.ConsigneeId = this.model.consignees.id;
      this.modelForUpdateData.ETA = this.model.eta;
      this.modelForUpdateData.bolDate = this.model.bolDate;
      this.modelForUpdateData.dateRangeStart = this.model.dateRangeStart;
      this.modelForUpdateData.dateRangeEnd = this.model.dateRangeEnd;
      this.modelForUpdateData.loadingStart = this.model.loadingStart;
      this.modelForUpdateData.loadingStop = this.model.loadingStop;
      this.modelForUpdateData.nmaSalesPrice = this.model.nmaSalesPrice;
      this.modelForUpdateData.cpcSalesPrice = this.model.cpcSalesPrice;
      this.modelForUpdateData.nxpNumber = this.model.nxpNumber;
      this.modelForUpdateData.nessNumber = this.model.nessNumber;
      this.modelForUpdateData.cciNumber = this.model.cciNumber;

      this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    });

    this.route.queryParams.subscribe(
      params => {
        const selectedTab = params['tab'];
        this.staticTabs.tabs[selectedTab > 0 ? selectedTab : 0].active = true;
      }
    );
  }
  selectTab(tabId: number) {
    this.staticTabs.tabs[tabId].active = true;
  }

  loadTerminals() {
    this.exportService.getTerminals().subscribe(
      (terminals: Terminal[]) => {
        this.terminals = terminals;
      },
      error => {
        this.alertify.error('Unable to Load Termnials');
      }
    );
  }
  loadProducts() {
    this.exportService.getProducts().subscribe(
      (products: Product[]) => {
        this.products = products;
      },
      error => {
        this.alertify.error('Unable to load Products');
      }
    );
  }
  loadLifters() {
    this.exportService.getLifters().subscribe(
      (lifters: Lifter[]) => {
        this.lifters = lifters;
      },
      error => {
        this.alertify.error('Unable to load Lifters');
      }
    );
  }
  loadCargoTypes() {
    this.exportService.getCargoTypes().subscribe(
      (cargotypes: CargoType[]) => {
        this.cargotypes = cargotypes;
      },
      error => {
        this.alertify.error('Umable to Load Cargo Types');
      }
    );
  }
  loadConsignees() {
    this.exportService.getConsignee().subscribe(
      (consignees: Consignee[]) => {
        this.consignees = consignees;
      },
      error => {
        this.alertify.error('Unable to load consignees');
      }
    );
  }
  loadStatus() {
    this.exportService.getStatusList().subscribe(
      (statuses: Status[]) => {
        this.statuses = statuses;
      },
      error => {
        this.alertify.error('Unable to load status');
      }
    );
  }

  loadVessels() {
    this.exportService.getVessels().subscribe(
      (vessels: Vessel[]) => {
        this.vessels = vessels;

        for (let i = 0; i < this.vessels.length; i++) {
          this.vesselList.push(
            String(this.vessels[i].name) +
            ' - ' +
            String(this.vessels[i].dwt) +
            ' - ' +
            String(this.vessels[i].flag)
          );
        }
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  loadLiftersForTerminal(id) {
    this.exportService.getLiftersForTerminal(id).subscribe(
      (lifters: Lifter[]) => {
        this.lifters = lifters;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  loadCargoTypesForLifter(id) {
    this.exportService.getCargoTypeForLifer(id).subscribe(
      (cargotypes: CargoType[]) => {
        this.cargotypes = cargotypes;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }

  loadDestinations() {
    this.exportService.getDestinations().subscribe(
      (destinations: Destination[]) => {
        this.destinationList = destinations;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadInspectors() {
    this.exportService.getInspectors().subscribe(
      (inspectors: Inspector[]) => {
        this.InspectorList = inspectors;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadVesselAgents() {
    this.exportService.getVesselAgents().subscribe(
      (vesselAgent: VesselAgent[]) => {
        this.vesselAgentList = vesselAgent;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  loadConsignor() {
    this.exportService.getConsignor().subscribe(
      (consignors: Consignor[]) => {
        this.consignorsList = consignors;
      },
      error => {
        this.alertify.error(error);
      }
    );
  }
  updateExport() {
    this.transformUpdatedata();
    // console.log(this.modelForUpdateData);
    this.exportService
      .updateExport(this.model.id, this.modelForUpdateData)
      .subscribe(
        next => {
          this.alertify.success('Export Details Successfully Updated');
          this.nav.navigate(['export-detail', this.model.id]);
        },
        error => {
          this.alertify.error(error);
        }
      );
    this.nav.navigate(['/export-detail/', this.model.id]);
  }

  transformUpdatedata() {
    this.modelForUpdateData.cargoNumber = this.model.cargoNumber;
    this.modelForUpdateData.terminalsId = this.model.terminals.id;
    this.modelForUpdateData.productsId = this.model.products.id;
    this.modelForUpdateData.liftersId = this.model.lifters.id;
    this.modelForUpdateData.norminatedCargo = this.model.norminatedCargo;
    this.modelForUpdateData.cargoTypeID = this.model.cargoTypes.id;
    this.modelForUpdateData.nxpNumber = this.model.nxpNumber;
    this.modelForUpdateData.nessNumber = this.model.nessNumber;
    this.modelForUpdateData.cciNumber = this.model.cciNumber;

    this.modelForUpdateData.flag = this.model.vessel.flag;
    this.modelForUpdateData.dwt = this.model.vessel.dwt;


    this.modelForUpdateData.comment = this.model.comment;
    this.modelForUpdateData.ConsigneeId = this.model.consignees.id;

    this.modelForUpdateData.StatusId = this.model.status.id;

    this.modelForUpdateData.ActualCargo = this.model.actualCargo;
    this.modelForUpdateData.ShipFigures = this.model.shipFigures;
    this.modelForUpdateData.API = this.model.api;
    this.modelForUpdateData.BSW = this.model.bsw;
    this.modelForUpdateData.ShipFigures = this.model.shipFigures;

    this.modelForUpdateData.OutterunCost = this.model.outterunCost;
    this.modelForUpdateData.LossClaimCost = this.model.lossClaimCost;
    this.modelForUpdateData.DemurrageCost = this.model.demurrageCost;

    this.modelForUpdateData.HasOutturn = this.hasOutturn.state;
    this.modelForUpdateData.HasDemurrage = this.hasDemurrage.state;
    this.modelForUpdateData.HasLossClaim = this.hasLossClaim.state;
    this.modelForUpdateData.FiscalPrice = this.model.fiscalPrice;
    this.modelForUpdateData.NMASalesPrice = this.model.NMASalesPrice;
    this.modelForUpdateData.CPCSalesPrice = this.model.CPCSalesPrice;
  }

  onSelectVessel(event: TypeaheadMatch): void {
    this.modelForUpdateData.vesselId = event.item.id;
    this.model.vessel.flag = event.item.flag;
    this.model.vessel.dwt = event.item.dwt;
    this.model.vessel.id = event.item.id;
  }
  onSelectDestination(event: TypeaheadMatch): void {
    this.modelForUpdateData.destinationid = event.item.id;
  }
  onSelectInspector(event: TypeaheadMatch): void {
    this.modelForUpdateData.inspectorId = event.item.id;
    this.hasInspector = true;
  }
  onSelectConsignor(event: TypeaheadMatch): void {
    this.modelForUpdateData.consignorId = event.item.id;
  }
  onSelectVesselAgent(event: TypeaheadMatch): void {
    this.modelForUpdateData.VesselAgentId = event.item.id;
  }

  onTerminalChange(e) {
    this.loadProductForTerminal(e);
    if (this.model.terminals.id == 2) {
      this.model.cargoNumber = 'AGB-';
      this.model.nominatedVolume = 975000;
      this.model.products.id = 1;
      this.model.flagOfVessel = "TBA";
      this.model.DWTofVessel = "TBA";
      this.model.cargoTypes.id = 2;
      this.model.lifters.id = 1;
    }
    if (this.model.terminals.id == 3) {
      this.model.cargoNumber = "ESC-";
      this.model.nominatedVolume = 9500000;
      this.model.products.id = 2;
      this.model.cargoTypes.id = 1;

    }
    if (this.model.terminals.id == 4) {
      this.model.cargoNumber = "EP-";
      this.model.nominatedVolume = 440000;
      this.model.cargoTypes.id = 8;
      this.model.lifters.id = 11;
      this.model.products.id = 4;
    }
    if (this.model.terminals.id == 1) {
      this.model.cargoNumber = "USAN-000";
      this.model.nominatedVolume = 1000000;
      this.model.cargoTypes.id = 16;
      this.model.lifters.id = 10;
      this.model.products.id = 3;
    }
    if (this.model.terminals.id == 5) {
      this.model.cargoNumber = "LPG-";
      this.model.nominatedVolume = 30000;
      this.model.products.id = 7;
      this.model.lifters.id = 13;
      this.model.cargoTypes.id = 16;
    }
    this.loadLiftersForTerminal(e);
  }


  onSelectProduct(e) {
    if (e == 3) {
      this.model.cargoTypes.id = 1;
    }
    // Diesel Export
    if (e == 4) {
      this.model.nominatedVolume = 310000;
    }
    if (e == 5) {
      this.model.nominatedVolume = 420000;
    }
    if (e == 6) {
      this.model.nominatedVolume = 250000;
    }
  }

  loadProductForTerminal(id) {
    this.exportService.getProductsForTerminal(id).subscribe(
      (products: Product[]) => {
        this.products = products;
      },
      error => {
        this.alertify.error('Unable to Load Products For Terminal');
      }
    );
  }
  onSelectLifter(e) {
    // console.log(e);
    this.loadCargoTypesForLifter(e);
  }

  addNewVessel() {
    this.exportService.createVessel(this.vesselToAddModel).subscribe(
      () => {
        this.modalRef.hide();
        this.alertify.success('New Vessel Created');
        this.loadVessels();
      },
      error => {
        this.alertify.error('Unable to Create Vessel');
      }
    );
    // this.ngOnInit();
  }

  addNewInspector() {
    this.exportService.createInspector(this.inspeectorToAddModel).subscribe(
      () => {
        this.modalRef.hide();
        this.loadInspectors();
        this.alertify.success('New Inspector Created');
      },
      error => {
        this.alertify.error('Unable to Create Inspector');
      }
    );
  }

  addNewVesselAgent() {
    this.exportService.createVesselAgent(this.vesselAgentToAddModel).subscribe(
      () => {
        this.modalRef.hide();
        this.loadVesselAgents();
        this.alertify.success('New Vessel Agent Created');
      },
      error => {
        this.alertify.error('Unable to Create Vessel Agent');
      }
    );
  }
  addNewConsignee() {
    this.exportService.createConsignee(this.consigneeToAddModel).subscribe(
      () => {
        this.modalRef.hide();
        this.loadConsignees();
        this.alertify.success('New Consignee Created');
      },
      error => {
        this.alertify.error('Unable to Create Consignee');
      }
    );
  }
  addNewConsignor() {
    this.exportService.createConsignor(this.consignorToAddModel).subscribe(
      () => {
        this.modalRef.hide();
        this.loadConsignor();
        this.alertify.success('New Consignor Created');
      },
      error => {
        this.alertify.error('Unable to Create Consignor');
      }
    );
  }
  addNewDestination() {
    this.exportService.createDestination(this.destinationToAddModel).subscribe(
      () => {
        this.modalRef.hide();
        this.loadDestinations();
        this.alertify.success('New Destinartion Created');
      },
      error => {
        this.alertify.error('Unable to Create Destinartion');
      }
    );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  closeModal() {
    this.modalRef.hide();
    this.modalRef = null;
  }
  onDateRangeStartValueChange(value: Date) {
    this.modelForUpdateData.dateRangeStart = this.dateService.urlDate(value);
  }
  onDateRangeEndValueChange(value: Date) {
    this.modelForUpdateData.dateRangeEnd = this.dateService.urlDate(value);
    // console.log(this.modelForUpdateData.DateRangeEnd);
  }
  onDateETAValueChange(value: Date) {
    this.modelForUpdateData.ETA = this.dateService.urlDate(value);
    // console.log(this.modelForUpdateData.ETA);
  }
  onBOLDateValueChange(value: Date) {
    this.modelForUpdateData.bolDate = this.dateService.urlDate(value);
    // console.log(this.modelForUpdateData.bolDate);
  }
  onLoadingStartValueChange(value: Date) {
    this.modelForUpdateData.loadingStart = value;
    // console.log(this.modelForUpdateData.loadingStart);
  }
  onLoadingEndValueChange(value: Date) {
    this.modelForUpdateData.loadingStop = value;
    // console.log(this.modelForUpdateData.loadingEnd);
  }
}
