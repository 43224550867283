import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { PopoverModule } from "ngx-bootstrap/popover";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { SortableModule } from "ngx-bootstrap/sortable";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { FileUploadModule } from "ng2-file-upload";
import { JwtModule } from "@auth0/angular-jwt";
import { TimeAgoPipe } from "time-ago-pipe";
// import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { AppComponent } from "./app.component";
import { TerminalComponent } from "./terminal/terminal.component";
import { NavComponent } from "./nav/nav.component";
import { AuthService } from "./_services/auth.service";
import { HomeComponent } from "./home/home.component";
import { RegisterComponent } from "./register/register.component";
import { AlertifyService } from "./_services/alertify.service";
import { DashBoardComponent } from "./dash-board/dash-board.component";
import { ExportComponent } from "./export/export.component";
import { LiftingProgramComponent } from "./lifting-program/lifting-program.component";
import { DocumentComponent } from "./document/document.component";
import { appRoute } from "./routes";
import { AuthGuard } from "./_guards/auth.guard";
import { ExportService } from "./_services/export.service";
import { ExportCardComponent } from "./export-card/export-card.component";
import { DashExportCardComponent } from "./dash-export-card/dash-export-card.component";
import { DashTableComponent } from "./dash-table/dash-table.component";
import { DashTabComponent } from "./dash-tab/dash-tab.component";
import { ExportMenuNavComponent } from "./export-menu-nav/export-menu-nav.component";
import { DashIssueBoardComponent } from "./dash-issue-board/dash-issue-board.component";
import { ExportDetailComponent } from "./export-detail/export-detail.component";
import { ExportDetailResolver } from "./_resolvers/export-detail.resolver";
import { AddExportModalComponent } from "./modals/add-export-modal/add-export-modal.component";
import { ExportDetailUpdateComponent } from "./export-detail-update/export-detail-update.component";
import { PreventUnsavedChangesGuard } from "./_guards/prevent-unsaved-changes-guard";
import { DocumentUploadComponent } from "./document-upload/document-upload.component";
import { ExportDetailActionComponent } from "./export-detail-action/export-detail-action.component";
import { ExportDetailUpdateActionsComponent } from "./export-detail-update-actions/export-detail-update-actions.component";
import { ExportActionsListUpdateViewComponent } from "./export-actions-list-update-view/export-actions-list-update-view.component";
import { NodeCommentResolver } from "./_resolvers/node-comment.resolver";
import { ExportDetailVesselComponent } from "./export-detail-vessel/export-detail-vessel.component";
import { ExportDetailDocumentComponent } from "./export-detail-document/export-detail-document.component";
import { ExportDetailFinancialComponent } from "./export-detail-financial/export-detail-financial.component";
import { ExportDetailAnalystComponent } from "./export-detail-analyst/export-detail-analyst.component";
import { ExportUpdateActivityCommentComponent } from "./export-update-activity-comment/export-update-activity-comment.component";
import { ExportTableComponent } from "./export-table/export-table.component";
import { ForecastHomeComponent } from "./forecast/forecast-home/forecast-home.component";
import { ForecastNavComponent } from "./forecast/forecast-nav/forecast-nav.component";
import { FooterComponent } from "./footer/footer.component";
import { NavChevronComponent } from "./nav-chevron/nav-chevron.component";
import { ExportDetailUpdateFinancialComponent } from "./export-detail-update-financial/export-detail-update-financial.component";
import { AdminHomeComponent } from "./admin/admin-home/admin-home.component";
import { AdminTerminalTabComponent } from "./admin/admin-terminal-tab/admin-terminal-tab.component";
import { AdminFinancialTabComponent } from "./admin/admin-financial-tab/admin-financial-tab.component";
import { DocumentHomeComponent } from "./document-home/document-home.component";
import { DemurrageHomeComponent } from "./demurrage/demurrage-home/demurrage-home.component";
import { DemurrageWorksheetModalComponent } from "./demurrage/demurrage-worksheet-modal/demurrage-worksheet-modal.component";
import { DemurrageClaimComponent } from "./demurrage/demurrage-claim/demurrage-claim.component";
import { DemurrageDocumentUploadComponent } from "./demurrage/demurrage-document-upload/demurrage-document-upload.component";
import { OpenDemurrageListComponent } from "./demurrage/open-demurrage-list/open-demurrage-list.component";
import { DemurrageListDetailComponent } from "./demurrage/demurrage-list-detail/demurrage-list-detail.component";
import { DocumentTemplateUploadComponent } from "./documenttemplate/document-template-upload/document-template-upload.component";
import { ForecastGeneratorComponent } from "./forecast/forecast-generator/forecast-generator.component";
import { ExportCreateComponent } from "./export-create/export-create.component";
import { FinancialHomeComponent } from "./financial/financial-home/financial-home.component";
import { NessComponent } from "./financial/ness/ness.component";
import { NessPayreqComponent } from "./financial/ness-payreq/ness-payreq.component";
import { ExchangeRateComponent } from "./financial/exchange-rate/exchange-rate.component";
import { CompletedNessComponent } from "./financial/completed-ness/completed-ness.component";
import { DocumentListComponent } from "./document-module/document-list/document-list.component";
import { DocumentListResolver } from "./_resolvers/document-list.resolver";
import { DateService } from "./_services/date.service";
import { ReportHomeComponent } from "./report/report-home/report-home.component";
import { EnergycomponentService } from "./_services/energycomponent.service";
import { AdminUserComponent } from "./admin/admin-user/admin-user.component";
import { httpInterceptorProviders } from "./_http-interceptors/http.interceptor";
import { TimespanToHoursPipe } from "./_pipes/timespan-to-hours";
import { TimeagoFixPipe } from "./_pipes/TimeagoFixPipe";

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [
    AppComponent,
    TerminalComponent,
    NavComponent,
    HomeComponent,
    RegisterComponent,
    DashBoardComponent,
    ExportComponent,
    LiftingProgramComponent,
    DocumentComponent,
    ExportCardComponent,
    DashExportCardComponent,
    DashTableComponent,
    DashTabComponent,
    ExportMenuNavComponent,
    DashIssueBoardComponent,
    ExportDetailComponent,
    AddExportModalComponent,
    ExportDetailUpdateComponent,
    DocumentUploadComponent,
    ExportDetailActionComponent,
    ExportDetailUpdateActionsComponent,
    ExportActionsListUpdateViewComponent,
    ExportDetailVesselComponent,
    ExportDetailDocumentComponent,
    ExportDetailFinancialComponent,
    ExportDetailAnalystComponent,
    ExportUpdateActivityCommentComponent,
    TimeagoFixPipe,
    TimespanToHoursPipe,
    ExportTableComponent,
    ForecastHomeComponent,
    ForecastNavComponent,
    FooterComponent,
    NavChevronComponent,
    ExportDetailUpdateFinancialComponent,
    AdminHomeComponent,
    AdminTerminalTabComponent,
    AdminFinancialTabComponent,
    DocumentHomeComponent,
    DemurrageHomeComponent,
    DemurrageWorksheetModalComponent,
    DemurrageClaimComponent,
    DemurrageDocumentUploadComponent,
    OpenDemurrageListComponent,
    DemurrageListDetailComponent,
    DocumentTemplateUploadComponent,
    ForecastGeneratorComponent,
    ExportCreateComponent,
    FinancialHomeComponent,
    NessComponent,
    NessPayreqComponent,
    ExchangeRateComponent,
    CompletedNessComponent,
    DocumentListComponent,
    ReportHomeComponent,
    AdminUserComponent,
  ],
  imports: [
    // ChartsModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    FormsModule,
    RouterModule.forRoot(appRoute, { relativeLinkResolution: "legacy" }),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    // ButtonsModule.forRoot(),
    PopoverModule.forRoot(),
    FileUploadModule,
    TypeaheadModule.forRoot(),
    CollapseModule.forRoot(),
    SortableModule.forRoot(),
    TimepickerModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [""],
        disallowedRoutes: ["/api/auth"],
      },
    }),
  ],
  providers: [
    httpInterceptorProviders,
    AuthService,
    AlertifyService,
    AuthGuard,
    ExportService,
    ExportDetailResolver,
    PreventUnsavedChangesGuard,
    NodeCommentResolver,
    DocumentListResolver,
    DateService,
    EnergycomponentService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
