import { Component, OnInit } from '@angular/core';
import { Terminal } from 'src/app/_models/terminal';
import { ExportService } from 'src/app/_services/export.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { DocumentCategory } from 'src/app/_models/documentcategory';
import { Export } from 'src/app/_models/export';
import { Lifter } from 'src/app/_models/lifter';
import { Pagination, PaginatedResult } from 'src/app/_models/pagination';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from 'src/app/_services/document.service';
import { DateService } from 'src/app/_services/date.service';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {

  terminalName: string;
  documentCategoryName: string;
  cargoNumber: string;
  lifterName: string;
  userName: string;
  dateRange: Date;

  exportArray: Export[];
  terminalArray: Terminal[];
  documentCategoryNameArray: DocumentCategory[];
  lifterArray: Lifter[];
  documentArray: any;

  documentParams: any = {};
  pagination: Pagination;

  enableLoader = true;
  documentCategoryname: any;

  dateStart: any;
  dateEnd: any;
  dateStartToSend: any;
  dateEndToSend: any;

  constructor(private exportService: ExportService, private alertify: AlertifyService
    , private route: ActivatedRoute, private documentService: DocumentService
    , private dateService: DateService) { }

  ngOnInit() {
    this.route.data.subscribe(
      data => {
        this.documentArray = data['documents'].result;
        this.pagination = data['documents'].pagination;
        this.enableLoader = false;
      }
    );
    this.loadTerminals();
    this.loadDocumentCategories();
    this.loadExports();
    this.loadLifters();

  }
  loadDocuments() {
    this.documentService.getAllExportDocuments(this.pagination.currentPage, this.pagination.itemsPerPage
      , this.terminalName, this.documentCategoryName, this.cargoNumber, this.lifterName, this.dateStartToSend
      , this.dateEndToSend)
    .subscribe(
      (res: PaginatedResult<Document[]>) => {
        this.documentArray = res.result;
        this.pagination = res.pagination;
        this.enableLoader = false;
      }, error => {
        this.alertify.error('Unable to Load Documents');
      }
    );
  }
  loadTerminals() {
    this.exportService.getTerminals().subscribe(
      (terminals: Terminal[]) => {
        this.terminalArray = terminals;
      }, error => {
        console.log('Error Occured');
      }
    );
  }
  loadDocumentCategories() {
    this.exportService.getDocumentCategoryList().subscribe(
      (documentCategories: DocumentCategory[]) => {
        this.documentCategoryNameArray = documentCategories;
      }, error => {
        console.log('Error Occured');
      }
    );
  }
  loadExports() {
    this.exportService.getInQueueExports().subscribe(
      (exports: Export[]) => {
        this.exportArray = exports;
      }, error => {
        this.alertify.error('Unable to Load Export');
      }
    );
  }

  loadLifters() {
    this.exportService.getLifters().subscribe(
      (lifters: Lifter[]) => {
        this.lifterArray = lifters;
      }, error => {
        console.log('Error Occured');
      }
    );
  }

  onSelectTerminal(event: TypeaheadMatch): void {
    this.terminalName = event.item.name;
  }
  onSelectDocumentCategory(event: TypeaheadMatch): void {
    this.documentCategoryName = event.item.name;
  }
  onSelectCargoNumber(event: TypeaheadMatch): void {
    this.cargoNumber = event.item.cargoNumber;
  }
  onSelectLifterName(event: TypeaheadMatch): void {
    this.lifterName = event.item.name;
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadDocuments();
  }
  onDateStartChange(value: Date): void {
    this.dateStartToSend = this.dateService.urlDate(value);
    const today = new Date(Date.now());
    this.dateEndToSend = this.dateService.urlDate(today);
  }
  onDateEndChange(value: Date): void {
    this.dateEndToSend = this.dateService.urlDate(value);
  }
  downloadDocument(e: number, fileName: string) {
    this.documentService.download(e).subscribe(
      data => {
        // console.log(fileName);
        // console.log(data);
        // const FileSaver = require('file-saver');
        // FileSaver.saveAs(data, fileName);
        saveAs(data, fileName);
        this.alertify.success('Download Successful');
      },
      error => {
        this.alertify.error('Unable to Download Document');
      }
    );
  }
  downloadAll(documentArrayList: any[]) {
    for (let index = 0; index < documentArrayList.length; index++) {
      this.downloadDocument(documentArrayList[index].id, documentArrayList[index].fileName);
    }
  }
  resetFilter() {
    this.terminalName = null ;
    this.documentCategoryName  = null ;
    this.cargoNumber  = null ;
    this.lifterName  = null ;
    this.dateStartToSend  = null ;
    this.dateEndToSend  = null ;
    this.loadDocuments();
  }
}
