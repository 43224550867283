<div class="container">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dash-board']">Demurrage Calculation List</a>
    </li>
  </ol>
  <div *ngIf="enableLoader" class="container">
      <div class="row">
        <div id="loader">
            <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="lading"></div>
        </div>
      </div>
  </div>

  <table *ngIf="!enableLoader" class="table table-hover">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Cargo # | Vessel</th>
        <th scope="col">Laycan</th>
        <th scope="col">G-Dem</th>
        <th scope="col">N-Dem</th>
        <th scope="col">Deduction</th>
        <th scope="col">Value</th>
        <th scope="col">WS</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let demurrageCalculation of demurrageCalculationsArray; index as i" (click)="openDetailToggle(demurrageCalculation)">
        <td>{{i + 1}}</td>
        <td popover="{{demurrageCalculation.export.lifters.name | uppercase}}">
          {{demurrageCalculation?.export.cargoNumber}}  | {{demurrageCalculation.export.vessel.name | uppercase}}</td>
        <td>{{demurrageCalculation.firstDayOfLaycan | date}}</td>
        <td>{{demurrageCalculation.grossDemurrage}}</td>
        <td>{{demurrageCalculation?.netDemurrage}}</td>
        <td>{{demurrageCalculation.deductions}}</td>
        <td>{{demurrageCalculation?.totalDemurrageAmount | currency}}</td>
        <td>{{demurrageCalculation.wsRate | currency}}</td>
        <td>
            <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-danger btn-sn dropdown-toggle" aria-controls="dropdown-basic">
                  Action <span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a class="dropdown-item">Generate Work Sheet</a></li>
                  <li role="menuitem"><a class="dropdown-item">Set as Agreed</a></li>
                  <li role="menuitem"><a class="dropdown-item">Generate Approval Letter</a></li>
                </ul>
              </div>
        </td>
      </tr>
    </tbody>
  </table>
  <form class="form-group mt-3" #demurrageForm="ngForm" *ngIf="detailPanelOpen">
      <div class="tab-panel" *ngIf="!calculationAvailable">
        <tabset class="member-tabset">
          <tab heading="Harbor Report Details">
            <div class="form-row mt-3">
              <div class="form-group col-md-4">
                <label for="inputEmail4">AFRA</label>
                <input type="text" class="form-control" [(ngModel)]="afraValue" placeholder="AFRA Value" name="afraValue" required>
              </div>
              <div class="form-group col-md-4">
                <label for="inputEmail4">WS</label>
                <input type="text" class="form-control" [(ngModel)]="wsValue" placeholder="AFRA Value" name="wsValue" required>
              </div>
              <div class="form-group col-md-4">
                <label for="inputEmail4">Ship Rate</label>
                <input type="text" class="form-control" [(ngModel)]="shipRate" placeholder="Ship Rate" name="shipRate">
              </div>
              <div class="form-group col-md-4">
                <label for="inputEmail4">Total Volume Loaded</label>
                <input type="text" class="form-control" [(ngModel)]="totalVolumeLoaded" placeholder="Total Volume Loaded" name="totalVolumeLoaded">
              </div>
            </div>
            <div class="form-row mt-3">
              <div class="form-group col-md-4">
                <label for="inputEmail4">First Laycan Day</label>
                <input type="text" [(ngModel)]="laycanDate" placeholder="Datepicker" class="form-control" bsDatepicker name="laycanDate"
                  required (bsValueChange)="onLaycanValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker [(ngModel)]="laycanTime" name="laycanTime" ></timepicker>
              </div>

              <div class="form-group col-md-4">
                <label for="inputEmail4">Notification of Arrival</label>
                <input type="text" [(ngModel)]="notificationOfArrivalDate" placeholder="Datepicker" class="form-control" bsDatepicker name="notificationOfArrivalDate"
                  required (bsValueChange)="onNotificationOfArrivalValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker [(ngModel)]="notificationOfArrivalTime" name="notificationOfArrivalTime" ></timepicker>
              </div>

              <div class="form-group col-md-4">
                <label for="inputEmail4">Laytime Start</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="layTimeStartDate" name="layTimeStartDate"
                  required (bsValueChange)="onLayTimeStartValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker name="layTimeStartTime" [(ngModel)]="layTimeStartTime" ></timepicker>
              </div>              
            </div>
            <div class="form-row">    
              <div class="form-group col-md-4">
                <label for="inputEmail4">Port Authority On Board</label>
                <input type="text" placeholder="Datepicker" class="form-control" [(ngModel)]="portAuthorityOnBoardDate" bsDatepicker name="portAuthorityOnBoardDate"
                  (bsValueChange)="onPortAthourityOnBoardValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker [(ngModel)]="portAuthorityOnBoardTime" name="portAuthorityOnBoardTime"></timepicker>
              </div>
              <div class="form-group col-md-4">
                <label for="inputEmail4">Free Pratique On Board</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="freePratiqueOnBoardDate" name="FreePratiqueOnBoardDate"
                  (bsValueChange)="onfreePratigueOnBoardValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker name="freePratigueOnBoardTime" [(ngModel)]="freePratigueOnBoardTime"></timepicker>
              </div>
            </div>

            <div class="form-row">    
              <div class="form-group col-md-4">
                <label for="inputEmail4">Cargo Paper Start</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="cargoPaperDocStartDate" name="cargoPaperDocStartDate"
                  (bsValueChange)="onCargoPaperDocStartValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker [(ngModel)]="cargoPaperDocStartTime" name="cargoPaperDocStartTime"></timepicker>
              </div>
              <div class="form-group col-md-4">
                <label for="inputEmail4">Complete Cargo Paper</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="cargoPaperDocEndDate" name="cargoPaperDocEndDate"
                  (bsValueChange)="onCargoPaperDocEndValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker name="cargoPaperDocEndTime" [(ngModel)]="cargoPaperDocEndTime"></timepicker>
              </div>
            </div>
    
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="inputEmail4">Pilot on Board</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="pilotOnBoardDate" name="pilotOnBoardDate"
                  (bsValueChange)="onPilotOnBoardValueChange($event)" required [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker name="pilotOnBoardTime" [(ngModel)]="pilotOnBoardTime" ></timepicker>
              </div>
              <div class="form-group col-md-4">
                <label for="inputEmail4">Completed Mooring</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="completedMooringDate" name="completedMooringDate"
                  (bsValueChange)="onCompletedMooringValueChange($event)" required [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker name="completedMooringTime" [(ngModel)]="completedMooringTime" ></timepicker>
              </div>
              <div class="form-group col-md-4">
                <label for="inputEmail4">Completed Disconnection of Hose</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="completedDisconnectionOfHoseDate"
                  name="completedDisconnectionOfHoseDate" (bsValueChange)="onCompletedDisconnectionOfHoseValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" required>
                <timepicker name="completedDisconnectionOfHoseTime" [(ngModel)]="completedDisconnectionOfHoseTime" ></timepicker>
              </div>
            </div>
            <div class="form-row">
              <button type="submit" class="btn btn-outline-danger mr-2" (click)="toggelHasEquipmentFailure()">
                <i class="fa fa-plus"></i>Equipment Failure
              </button>
              <button type="submit" class="btn btn-outline-danger ml-2" (click)="toggleHasWeatherDelays()">
                <i class="fa fa-plus"></i>Weather Delays
              </button>
            </div>
    
            <div *ngIf="hasEquipmentBreakDown" class="form-row">
              <div class="form-group col-md-4 mt-2">
                <label for="inputEmail4">Equipment BreakDown Start</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="equipmentBreakDownStartDate" name="equipmentBreakDownStartDate"
                  (bsValueChange)="onEquipmentBreakDownStartValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker name="equipmentBreakDownStartTime" [(ngModel)]="equipmentBreakDownStartTime" ></timepicker>
              </div>
              <div class="form-group col-md-4 mt-2">
                <label for="inputEmail4">Equipment BreakDown End</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="equipmentBreakDownEndsDate" name="equipmentBreakDownEndsDate"
                  (bsValueChange)="onEquipmentBreakEndValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker name="equipmentBreakDownEndsTime" [(ngModel)]="equipmentBreakDownEndsTime" ></timepicker>
              </div>
            </div>
            <div *ngIf="hasWeatherDelay" class="form-row">
              <div class="form-group col-md-4 mt-2">
                <label for="inputEmail4">Weather Delay Start</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="weatherDelayStartDate" name="weatherDelayStartDate"
                  (bsValueChange)="onWeatherDelayStartValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker name="weatherDelayStartTime" [(ngModel)]="weatherDelayStartTime" ></timepicker>
              </div>
              <div class="form-group col-md-4 mt-2">
                <label for="inputEmail4">Weather Delay End</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="weatherDelayEndDate" name="weatherDelayEndDate"
                  (bsValueChange)="onWeatherDelayEndValueChange($event)" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
                <timepicker name="weatherDelayEndTime" [(ngModel)]="weatherDelayEndTime" ></timepicker>
              </div>
            </div>
          </tab>
        </tabset>
      </div>
    
      <div class="form-row">
        <button type="submit" (click)="calculateDemurrage()" [disabled]="!demurrageForm.valid" *ngIf="!calculationAvailable" class="btn btn-outline-primary btn-lg mt-3">Calculate Demurrage</button>
      </div>
    </form>
</div>
<div *ngIf="calculationAvailable" class="container">
  <app-demurrage-worksheet-modal [returnedDemurrageModel]="returnedDemurrageModel" [export]="export"></app-demurrage-worksheet-modal>
</div>


