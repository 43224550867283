import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, filter, switchMap } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "../../environments/environment";
import * as jose from "../../../node_modules/jose";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseUrl = environment.apiUrl + "auth/";
  jwtHelper = new JwtHelperService();
  decodedToken: any;

  constructor(private http: HttpClient) {}

  login(model: any) {
    return this.http.post(this.baseUrl + "login", model).pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          localStorage.setItem("token", user.token);
          this.decodedToken = this.jwtHelper.decodeToken(user.token);
          // console.log(this.decodedToken);
        }
      })
    );
  }
  /*
  Register Method to Register a New User
  This method returns an observable method that needs to be subscribed to
  */
  register(model: any) {
    return this.http.post(this.baseUrl + "register", model);
  }
  /*
  Check is Token is expire
  */
  loggedIn() {
    const token = localStorage.getItem("token");
    return !this.jwtHelper.isTokenExpired(token) && this.verifySignature();
  }
  /*
  validate encryption algorithm
  */
  verifySignature() {
    const token = localStorage.getItem("token");
    if (token) {
      const protectedHeader = jose.decodeProtectedHeader(token);
      if (protectedHeader.alg == "HS512") return true;
      else {
        console.log("token signature is not matched");
        return false;
      }
    }
    console.log("token signature error");
    return false;
  }
}
