<div class="container">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="['/dash-board']">Document Home</a>
    </li>
  </ol>
  <ul class="nav nav-pills">
    <li class="nav-item mr-2">
      <a class="nav-link active" (click)="createModeToggel()"><i class="fa fa-plus"></i> Add Template</a>
    </li>
    <!-- <li class="nav-item mr-2">
      <a class="nav-link active"><i class="fa fa-plus"></i> Template Category</a>
    </li> -->
  </ul>
  <app-document-template-upload *ngIf="createMode"></app-document-template-upload>
  <app-document-list></app-document-list>
  <!-- <app-document-home></app-document-home> -->
</div>


