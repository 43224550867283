<div class="row breadcrumb">
  <div class="col-sm-3">
    <label>Vessel Name</label>
    <input [(ngModel)]="filterModel.vesselName" [typeahead]="vesselNames" typeaheadOptionField="name" class="form-control"
      (typeaheadOnSelect)="onSelect()">
  </div>
  <div class="col-sm-2">
    <label for="inputTerminal">Terminal</label>
    <select class="form-control" id="inputTerminal" name="terminalName" [(ngModel)]="filterModel.terminalName" required>
      <option *ngFor="let terminal of terminals" value="{{terminal.name}}">{{terminal.name}}</option>
    </select>
  </div>
  <div class="col-sm-2">
    <label for="inputLifter">Lifter</label>
    <select class="form-control" id="inputLifter" name="lifterName" [(ngModel)]="filterModel.lifterName" required>
      <option *ngFor="let lifter of lifters" value="{{lifter.name}}">{{lifter.name}}</option>
    </select>
  </div>
  <div class="col-sm-2">
    <label for="inputProductStream">Product</label>
    <select class="form-control" id="inputProductStream" name="productName" [(ngModel)]="filterModel.productName" required>
      <option *ngFor="let product of products" value="{{product.name}}">{{product.name}}</option>
    </select>
  </div>
  <div class="col-sm-3">
    <label for="productNames">Date Range</label>
    <input type="text" placeholder="Daterangepicker" class="form-control" [(ngModel)]="dateRange" name="dateRange"
      bsDaterangepicker>
  </div>
  <div class="cole-sm-3">
    <label for="exampleSelect2">Status</label>
    <select class="form-control mt-2" [(ngModel)]="filterModel.statusId" name="filterModel.statusId">
      <option *ngFor="let status of demurrageStatus" value="{{status.id}}">{{status.name}}</option>
    </select>
  </div>
</div>
<div class="mt-4 mb-4 d-flex justify-content-end">
  <button class="btn btn-outline-primary mr-2" (click)="runQuery()"><i class="fa fa-search"></i> Filter</button>
</div>
<table class="table table-hover">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Cargo ID</th>
      <!-- <th scope="col">Recieved</th> -->
      <th scope="col">Claim Recieved</th>
      <th scope="col">Terminal</th>
      <th scope="col">Lifter</th>
      <th scope="col">Open</th>
      <th scope="col">Status</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let demurrage of filteredOpenDemurrages; index as i">
      <th scope="row">{{i + 1}}</th>
      <td [routerLink]="['/demurrage-detail/',demurrage.exportId]">{{demurrage.export?.cargoNumber}}
        {{demurrage.export?.vessel.name | uppercase}}</td>
      <!-- <td>{{demurrage?.initialClaimRecievedDate | date}}</td> -->
      <td>{{demurrage?.revisedClaimValue | currency}}</td>
      <td>{{demurrage.export?.terminals.name | uppercase}}</td>
      <td>{{demurrage.export?.lifters.name | uppercase}}</td>
      <td>{{demurrage.initialClaimRecievedDate | timeAgo}}</td>
      <td (click)="openModal(template,demurrage)">
        <span class="badge " 
          [class.badge-danger]="demurrage.demurrageStatus.id == 1"
          [class.badge-warning]="demurrage.demurrageStatus.id == 2"
          [class.badge-warning]="demurrage.demurrageStatus.id == 3"
          [class.badge-warning]="demurrage.demurrageStatus.id == 4"
          [class.badge-info]="demurrage.demurrageStatus.id == 5" 
          [class.badge-info]="demurrage.demurrageStatus.id == 6"
          [class.badge-success]="demurrage.demurrageStatus.id == 7" 
          popover={{demurrage.demurrageStatus.name}}>
          {{demurrage.demurrageStatus.name}}</span>
      </td>
      <td>
        <div class="btn-group" dropdown>
          <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-danger btn-sn dropdown-toggle"
            aria-controls="dropdown-basic">
            Action <span class="caret"></span>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li role="menuitem">
              <a class="dropdown-item" (click)="openModal(template,demurrage.id)">Update Status</a>
            </li>
            <!-- <li role="menuitem"><a class="dropdown-item">Add Comments</a></li>
                <li role="menuitem"><a class="dropdown-item">Generate Approval Letter</a></li> -->
          </ul>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Update Demurrage Status</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form ng-form="demurrageStatusForm">
    <div class="modal-body">
      <!-- <div class="alert alert-dismissible alert-warning">
          <button type="button" class="close" data-dismiss="warning">&times;</button>
          <strong>Demurrage Comments!</strong> <a class="alert-link">Please ensure you enter comments before updating 
            the demurrage status
          </a> and try submitting again.
        </div>
        <div class="form-group">
            <label for="exampleTextarea">Comments</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div> -->
      <select class="form-control mt-2" [(ngModel)]="statusId" name="statusId" (change)="updateActivityStatus()">
        <option *ngFor="let status of demurrageStatus" value="{{status.id}}">{{status.name}}</option>
      </select>
      <button class="btn btn-primary mt-2" (click)="updateDemurrageStatus()">Save</button>
    </div>
  </form>
</ng-template>