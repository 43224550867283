import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExportService } from 'src/app/_services/export.service';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.css']
})
export class AdminUserComponent implements OnInit {

  modalRef: BsModalRef;
  userToDelete: number;
  usersList: User[];
  public userForm = {
    username: null,
    password: null,
    confirmPassword: null
  };

  constructor(private modalService: BsModalService,
    private authService: AuthService,
    private alertify: AlertifyService,
    private exportService: ExportService) { }

  ngOnInit() {
    this.loadUsers();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  openDeleteModal(template: TemplateRef<any>, id: number) {
    this.modalRef = this.modalService.show(template);
    this.userToDelete = id;
  }
  submitAddUserForm() {
    if(this.userForm.password === this.userForm.confirmPassword) {
      this.authService.register(this.userForm).subscribe(
        () => {
          this.alertify.success('New User Successfully Added');
          this.loadUsers();
        }, error => {
          this.alertify.error('Unable to Add new User');
        }
      );
    } else {
      this.alertify.error('Password Does not Match');
    }
  }
  deleteUser() {

    this.exportService.deleteUser(this.userToDelete).subscribe(
      () => {
        this.alertify.success('User Successfully Deleted');
        this.loadUsers();
        this.modalRef.hide();
      }, error => {
        this.alertify.error(error);
      }
    );
  }
  closeAddUserForm() {
    this.modalRef.hide();
  }

  loadUsers() {
    this.exportService.getAllUsers().subscribe(
      (users: User[]) => {
        this.usersList = users;
      }, error => {
        this.alertify.error('Unable to Load Users');
      }
    );
  }
}
