<div>
  <div class="mt-3">
    <ul class="nav nav-pills">
      <li class="nav-item mr-2">
        <a class="nav-link active" (click)="openModal(addUser)">
          <i class="fa fa-plus"></i> Create New User</a>
      </li>
      <li class="nav-item mr-2">
        <a class="nav-link active" [routerLink]="['/export/list']">
          <i class="fa fa-list-ul"></i> View Exports as List Export</a>
      </li>
    </ul>
  </div>
  <div>
    <div class="row">
      <div class="col-md-3 mt-2">
          <ul *ngFor="let user of usersList" class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center">
                {{user.username}}
                <span class="badge badge-danger badge-pill" (click)="openDeleteModal(confirmationModal, user.id)"><i class="fa fa-trash"></i>Delete</span>
              </li>
            </ul>
      </div>
      <div class="col-md-4">
        
      </div>
      <div class="col-md-4">
        
      </div>
    </div>
  </div>
</div>

<ng-template #addUser>
    <div class="modal-header">
      <h4 class="modal-title pull-left">ADD NEW USER</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form #addVesselForm="ngForm">
      <div class="modal-body">
        <div class="form-row">
          <input type="text" class="form-control" placeholder="UserName" [(ngModel)]="userForm.username"
           name="userForm.username" required>
        </div>
        <div class="form-row mt-2">
          <input type="password" class="form-control" placeholder="Password" [(ngModel)]="userForm.password"
           name="userForm.password" required>
        </div>
        <div class="form-row mt-2">
          <input type="password" class="form-control" placeholder="Confirm Password" [(ngModel)]="userForm.confirmPassword"
           name="userForm.confirmPassword" required>
        </div>
        <div class="card-footer">
          <div class="btn-group d-flex">
              <button type="button" [disabled]="!addVesselForm.valid" class="btn btn-primary w-100"
              (click)="submitAddUserForm()">Save</button>
              <button type="button"class="btn btn-danger w-100"
              (click)="closeAddUserForm()">Close</button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #confirmationModal>
      <div class="modal-header">
        <h4 class="modal-title pull-left">Confirm Delete Action</h4>
      </div>
      <div class="modal-body">
       Are you absolutely sure you want to delete this user<br>
        <button (click)="deleteUser()" type="button" class="btn btn-danger">Delete</button>
      </div>
    </ng-template>
