<div class="card mb-3"
  [class.bg-success]="export?.terminals.name == 'Escravos'"
  [class.bg-warning]="export?.terminals.name == 'EGTL'"
  [class.bg-secondary]="export?.terminals.name == 'LPG'"
  [class.bg-primary]="export?.terminals.name == 'USAN'"
  >
  <h6 class="card-header text-center">{{export?.vessel?.name ?? export?.vessel?.name | uppercase}}</h6>
    <!-- <img style="height: 150px; width: 100%; display: block;" src="{{export.lifters.lifterPhotoUrl}}" alt="Card image"> -->
  <div class="card-body text-center" [routerLink]="['/export-detail',export.id]">
    <h6 class="card-subtitle text-muted">{{export?.cargoNumber}}</h6>
    <img src="{{export?.lifters?.lifterPhotoUrl}}" width="100" height="100"  >
    <h6 class="card-title text-center">ETA : {{export?.eta | date}}</h6>
    <h6 class="card-subtitle text-muted">{{export?.cargoTypes?.name}}</h6>
    <ul class="list-group list-group-flush">
      <li class="list-group-item text-center text-muted">{{ export?.lifters?.name | uppercase}} - {{ export?.terminals?.name | uppercase}}</li>
      <li class="list-group-item text-center">{{export?.dateRangeStart | date:' d MMM'}} - {{export?.dateRangeEnd | date:'d MMM, y'}}</li>
      <!-- <li class="list-group-item text-center">{{export.norminatedCargo}}</li>  -->
      <li class="list-group-item text-center"><h4>{{export?.status?.label}}</h4></li>
    </ul>
  </div>
  
</div>